<template>
    <div id="headerComponents">
        <div class="header-page">
            <div class="header-left">
                <div class="logo">
                    <div class="logo-img" @click="goView('homePage')"><img src="../assets/images/logo.png"></div>
                </div>
            </div>
            <div class="header-right">
                <template v-if='isLargeScreen'>
                    <div class="header-right-list">
                        <div class="myNFTs ml40" :class="{'linkClass': linkClass==='market'}" @click="goView('market')">Market</div>
                        <div class="myNFTs ml40" :class="{'linkClass': linkClass==='selectTheNft'}" @click="goView('selectTheNft')">Mint</div>
                        <div class="myNFTs ml40 community">
                            <el-tooltip placement="bottom">
                                <div slot="content">
                                    <div class="community-li">
                                        <a :href="`${publicPath}file/HistoryDAO WhitePaperFA.pdf`" target="_blank">
                                            <div class="community-li-text">Whitepaper</div>
                                            <div class="community-li-icon"><i class="el-icon-arrow-right" /></div>
                                        </a>
                                    </div>
                                    <div class="community-li">
                                        <a :href="`${publicPath}file/HistoryDAO Smart Contract Audit Report.pdf`" target="_blank">
                                            <div class="community-li-text">Audit Report</div>
                                            <div class="community-li-icon"><i class="el-icon-arrow-right" /></div>
                                        </a>
                                    </div>
                                    <!--  <div class="community-li">
                                        <a href="https://medium.com/@historyofficial11" target="_blank">
                                            <div class="community-li-text">Blog</div>
                                            <div class="community-li-icon"><i class="el-icon-arrow-right" /></div>
                                        </a>
                                    </div> -->
                                    <!--  <div class="community-li">
                                        <a href="http://help.historydao.io/en/" target="_blank">
                                            <div class="community-li-text">Help Center</div>
                                            <div class="community-li-icon"><i class="el-icon-arrow-right" /></div>
                                        </a>
                                    </div> -->
                                    <div class="iconList">
                                        <div class="iconImg iconImg1"><a href="https://twitter.com/History_DAO" target="_blank"><img src="../assets/images/svg1.png"></a></div>
                                        <div class="iconImg"><a href="https://medium.com/@HistoryDAO" target="_blank"><img src="../assets/images/svg2.png"></a></div>
                                        <div class="iconImg"><a href="https://discord.com/invite/BztebRcpyY" target="_blank"><img src="../assets/images/svg3.png"></a></div>
                                        <div class="iconImg"><a href="https://www.youtube.com/c/HistoryDAO" target="_blank"><img src="../assets/images/svg4.png"></a></div>
                                    </div>
                                </div>
                                <el-button>Community <i class="el-icon-arrow-down" /></el-button>
                            </el-tooltip>
                        </div>
                        <div class="myNFTs ml40" :class="{'linkClass': linkClass==='myNFTs'}" @click="goView('myNFTs')">My Collections</div>
                        <el-popover popper-class="more-chain-popover" :visible-arrow="false" v-model="showChainListPopover" placement="bottom" width="220" trigger="click" v-if="walletInfo.address">
                            <div slot="reference" class="chain ml40" v-if="chainList[chainId]">
                                <img :src="chainList[chainId]&&chainList[chainId].img" alt="" class="icon1">
                                <span class="name sm-hidden">{{chainList[chainId]&&chainList[chainId].name}}</span>
                                <svg class="icon2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_1252_42612" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                        <rect width="16" height="16" />
                                    </mask>
                                    <g mask="url(#mask0_1252_42612)">
                                        <path d="M4 6L8 10L12 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                </svg>
                            </div>
                            <div slot="reference" class="wrong-network flex aic jcc ml40" v-else>
                                <img src="@/assets/images/page-header/wrong.svg" alt="">
                                <span class="text">Wrong Network</span>
                            </div>
                            <div class="more-info">
                                <div class="label">Select a network</div>
                                <div class="list">
                                    <div class="item flex aic jcsb" :class="{'ac': item.id==chainId}" v-for="(item,index) in switchChainList" :key="index">
                                        <div class="fl flex aic" @click="switchChain(item.id)">
                                            <img class="img" :src="item.img" alt="">
                                            <span class="text">{{item.name}}</span>
                                        </div>
                                        <div class="fr"></div>
                                    </div>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                    <div class="wallet ml40"><span v-if="!walletInfo.address" @click="showWalletList=true; ">Connect your wallet</span><span v-else>{{walletInfo.address|addressFilter}}</span></div>
                </template>
                <template v-else>
                    <img v-show="drawer==false" class="menu" src="@/assets/images/page-header/menu.svg" alt="" @click="drawer=true">
                    <img v-show="drawer==true" class="menu close" src="@/assets/images/page-header/close.svg" alt="" @click="drawer=false">
                    <el-drawer class="header-drawer" :append-to-body="true" :visible.sync="drawer" direction="ttb" :show-close="false" :modal="false" size="100%">
                        <el-collapse accordion>
                            <div class="el-collapse-item" @click="goView('market');drawer=false">
                                <div role="tab">
                                    <div role="button" tabindex="0" class="el-collapse-item__header">Market<i class="el-collapse-item__arrow el-icon-arrow-right"></i></div>
                                </div>
                            </div>
                            <div class="el-collapse-item" @click="goView('selectTheNft');drawer=false">
                                <div role="tab">
                                    <div role="button" tabindex="0" class="el-collapse-item__header">Mint<i class="el-collapse-item__arrow el-icon-arrow-right"></i></div>
                                </div>
                            </div>
                            <el-collapse-item title="Community">
                                <div class="links">
                                    <a class="item" @click="drawer=false" :href="`${publicPath}file/HistoryDAO WhitePaperFA.pdf`" target="_blank">
                                        <div class="community-li-text">Whitepaper</div>
                                        <div class="community-li-icon">
                                            <!-- <i class="el-icon-arrow-right" /> -->
                                        </div>
                                    </a>
                                    <a class="item" @click="drawer=false" :href="`${publicPath}file/HistoryDAO Smart Contract Audit Report.pdf`" target="_blank">
                                        <div class="community-li-text">Audit Report</div>
                                        <div class="community-li-icon">
                                            <!-- <i class="el-icon-arrow-right" /> -->
                                        </div>
                                    </a>
                                    <!-- <a class="item" @click="drawer=false" href="http://help.historydao.io/en/" target="_blank">
                                        <div class="community-li-text">Help Center</div>
                                        <div class="community-li-icon">
                                        </div>
                                    </a> -->
                                    <div class="item p40">
                                        <div class="iconImg iconImg1"><a @click="drawer=false" href="https://twitter.com/History_DAO" target="_blank"><img src="../assets/images/svg1.png"></a></div>
                                        <div class="iconImg"><a @click="drawer=false" href="https://medium.com/@HistoryDAO" target="_blank"><img src="../assets/images/svg2.png"></a></div>
                                        <div class="iconImg"><a @click="drawer=false" href="https://discord.com/invite/BztebRcpyY" target="_blank"><img src="../assets/images/svg3.png"></a></div>
                                        <div class="iconImg"><a @click="drawer=false" href="https://www.youtube.com/c/HistoryDAO" target="_blank"><img src="../assets/images/svg4.png"></a></div>
                                    </div>
                                </div>
                            </el-collapse-item>
                            <div class="el-collapse-item" @click="goView('myNFTs');drawer=false">
                                <div role="tab">
                                    <div role="button" tabindex="0" class="el-collapse-item__header">My Collections<i class="el-collapse-item__arrow el-icon-arrow-right"></i></div>
                                </div>
                            </div>
                            <el-collapse-item title="Select a network">
                                <div class="list">
                                    <div class="item flex aic jcsb" :class="{'ac': item.id==chainId}" v-for="(item,index) in switchChainList" :key="index" @click="drawer=false">
                                        <div class="fl flex aic" @click="switchChain(item.id)">
                                            <img class="img" :src="item.img" alt="">
                                            <span class="text">{{item.name}}</span>
                                        </div>
                                        <div class="fr"></div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <div class="wallet"><span v-if="!walletInfo.address" @click="connectWallet('mobile')">Connect your wallet</span><span v-else>{{walletInfo.address|addressFilter}}</span></div>
                    </el-drawer>
                </template>
            </div>
        </div>
        <el-dialog title="Connect your wallet" :visible.sync="showWalletList" width="400px" :modal-append-to-body="true" append-to-body custom-class="wallet-list-dialog" :close-on-click-modal="false">
            <div class="wallet-list">
                <div class="wallet-item MetaMask" @click="connectWallet('MetaMask')">
                    <img src="@/assets/images/page-header/metamask.png" alt="">
                    <span class="text">MetaMask</span>
                </div>
                <div class="wallet-item BitKeep" @click="connectWallet('BitKeep')">
                    <img src="@/assets/images/page-header/bitkeep.png" alt="">
                    <span class="text">BitKeep</span>
                </div>
                <div class="wallet-item okexchain" @click="connectWallet('OKX Wallet')">
                    <img src="@/assets/images/page-header/OKXWallet.svg" alt="">
                    <span class="text">OKX Wallet</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
const wallet = require('../web3/common/wallet.js');
export default {
    data() {
        return {
            showWalletList: false,
            publicPath: process.env.BASE_URL,
            inject: ['reload'],
            linkClass: 'homePage',
            addressValue: [],
            showChainListPopover: false,
            chainList: {
                56: {
                    name: "BNB Chain",
                    img: require('@/assets/images/page-header/BNB.png')
                },
                1: {
                    name: "Ethereum",
                    img: require('@/assets/images/page-header/ETH.png')
                },
                42161: {
                    name: "Arbitrum",
                    img: require('@/assets/images/page-header/Arbitrum/ETH.png')
                },
                324: {
                    name: "zkSync Era",
                    img: require('@/assets/images/page-header/zkSync/ETH.svg')
                }, 
                280: {
                    name: "zkSync Era T",
                    img: require('@/assets/images/page-header/zkSync/ETH.svg')
                }, 
                66: {
                    name: "OKXChain",
                    img: require('@/assets/images/page-header/OKT.svg')
                }
                // 421613: {
                //     name: "Arbitrum Goerli",
                //     img: require('@/assets/images/page-header/Arbitrum/ETH.png')
                // }
                // 137: {
                //     name: "Polygon",
                //     img: require('@/assets/images/page-header/MATIC.png')
                // }
            },
            switchChainList: [
                {
                    id: 56,
                    name: "BNB Chain",
                    img: require('@/assets/images/page-header/BNB.png')
                }, {
                    id: 1,
                    name: "Ethereum",
                    img: require('@/assets/images/page-header/ETH.png')
                }, {
                    id: 42161,
                    name: "Arbitrum",
                    img: require('@/assets/images/page-header/Arbitrum/ETH.png')
                },
                {
                    id: 324,
                    name: "zkSync Era",
                    img: require('@/assets/images/page-header/zkSync/ETH.svg')
                },
                {
                    id: 66,
                    name: "OKXChain",
                    img: require('@/assets/images/page-header/OKT.svg')
                },
                // {
                //     id: 421613,
                //     name: "Arbitrum Goerli",
                //     img: require('@/assets/images/page-header/Arbitrum/ETH.png')
                // },
                // {
                //     id: 137,
                //     name: "Polygon",
                //     img: require('@/assets/images/page-header/MATIC.png')
                // }

            ],
            isLargeScreen: true,
            drawer: false,

        }
    },
    computed: {
        walletInfo() {
            return this.$store.getters.walletInfo;
        },
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        },
        provider() {
            return this.$store.getters.provider;
        },

    },
    watch: {
        '$route'(from) {
            this.linkClass = from.name;
        },
        '$store.getters.walletInfo.address'(address) {
            if (address) {
                this.showWalletList = false;
            }
        }
    },
    mounted() {
        this.isLargeScreen = window.innerWidth - 0 > 1125 ? true : false;
        window.onresize = () => {
            this.isLargeScreen = window.innerWidth - 0 > 1125 ? true : false;
        };
        this.linkClass = this.$route.name;
    },
    methods: {
        goView(name) {
            this.$router.push(`/${name}`);
            this.linkClass = name;
        },
        connectWallet(type) {
            if (type === 'mobile') {
                if (window.okxwallet || window.okexchain) {
                    this.$emit('connectToWallet', 'OKX Wallet')
                } else if (window.bitkeep && window.bitkeep.ethereum) {
                    this.$emit('connectToWallet', 'BitKeep')
                } else if (window.ethereum) {
                    this.$emit('connectToWallet', 'MetaMask')
                }
            } else {
                this.$emit('connectToWallet', type)
            }
        },
        switchChain(id) {
            if (id == this.chainId) {
                return;
            }
            wallet.switchEthereumChain('0x' + id.toString(16), this.provider).then(() => {
                console.log("Chain change success.");
            }).catch((err) => {
                console.error(err.message);
            })

        }
    },
    filters: {
        addressFilter(val) {
            if (!val) {
                return '';
            }
            return val.substr(0, 6) + '...' + val.substr(-4);
        }
    }
}
</script>
<style lang="scss" scoped>
#headerComponents {
    width: 100%;
    height: 75px;
    background: #232323;
    box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);
    display: flex;
    z-index: 999;
    position: fixed;
    top: 0;
    opacity: .98;

    .header-left {
        display: flex;
        align-items: center;

        .logo {
            .logo-img {
                display: flex;
                cursor: pointer;

                img {
                    width: 115px;
                    height: 44px;
                }
            }
        }
    }

    .header-right {
        display: flex;
        align-items: center;
        white-space: nowrap;

        .header-right-list {
            display: flex;
            align-items: center;
        }

        .myNFTs {
            display: flex;
            font-family: Poppins-Bold, Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            color: rgba(250, 250, 250, 0.75183);
            cursor: pointer;
            height: 75px;
            line-height: 75px;
        }

        .linkClass {
            color: #fff;
            border-bottom: 4px solid #FFE161;
        }

        .wallet {
            padding: 0 28px;
            min-width: 173px;
            height: 50px;
            background: #FFE161;
            border-radius: 0.1rem;
            font-family: Poppins-Bold, Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 50px;
            color: #614F00;
            text-align: center;
            cursor: pointer;
        }

        .ml40 {
            margin-left: 40px;
        }

        .chain {
            height: 36px;
            border-radius: 12px;
            // margin-right: 16px;
            padding: 0 14px 0 10px;
            cursor: pointer;
            background: linear-gradient(270deg, #403721 1.16%, #403C3C 100%);
            border-radius: 10px;
            display: flex;
            align-items: center;

            .icon1 {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }

            .name {
                font-size: 16px;
                // font-family: Roboto-Regular, Roboto;
                font-family: Poppins-Bold, Poppins;

                font-weight: 600;
                margin-right: 4px;
                color: #fff;
            }

            .icon2 {
                width: 16px;
                height: 16px;

                rect {
                    fill: #fff;
                }

                path {
                    stroke: #fff;
                }
            }
        }

    }
}

.community-li-icon ::v-deep i {
    font-weight: bold;
}

.community ::v-deep i {
    font-weight: bold;
}

.community-li {
    padding: 0.08rem 0.05rem 0.08rem 0.05rem;
    cursor: pointer;

    a {
        display: flex;
        text-decoration: none;
    }

    .community-li-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    .community-li-icon {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        margin-left: auto;
        margin-top: -2px;
    }
}

.iconList {
    display: flex;
    margin-top: 18px;
    padding-bottom: 5px;

    .iconImg {
        width: 24px;
        height: 24px;
        display: flex;
        flex: 1;
        justify-content: center;

        a {
            width: 24px;
            height: 24px;

            img {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
    }

    .iconImg:nth-of-type(1) {
        margin-left: -5px;
    }

    .iconImg:nth-of-type(2) {
        margin-left: 15px;
    }

    .iconImg:nth-of-type(3) {
        margin-left: 15px;
    }

    .iconImg:nth-of-type(4) {
        margin-left: 15px;
    }
}

.community ::v-deep .el-button {
    color: rgba(250, 250, 250, 0.75183);
    font-size: 16px;
    font-weight: 600;
    background: none;
    border: none;
    padding: 0;
}

.community ::v-deep .el-button:hover {
    color: rgba(250, 250, 250, 0.75183);
}

.header-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    margin: 0 auto;
    width: 100%;
    max-width: 1760px;
    padding: 0 40px;

}


@media (max-width: 1125px) {
    #headerComponents {
        height: 3.68641rem;

        .header-page {
            padding: 0 1.024rem;
            height: 3.68641rem;

            .logo-img {
                img {
                    width: 5.88802rem !important;
                    height: 2.25281rem !important;
                }
            }

            .menu {
                cursor: pointer;
                width: 1.3312rem;
                height: auto;

                &.close {
                    width: 1.024rem;
                }
            }

        }
    }


    ::v-deep.header-drawer {
        height: calc(100% - 3.68641rem) !important;
        top: 3.68641rem;
        z-index: 99999999999 !important;

        .el-drawer {
            box-shadow: none !important;
            // animation: none !important;
        }

        .el-drawer__container.el-drawer__open {
            .el-drawer__header {
                display: none;
            }

            .el-drawer__body {
                .el-collapse {
                    border: 0;

                    &-item {
                        padding: 0 1.024rem;

                        &__header {
                            border: 0;
                            height: 100%;
                            height: 3.68641rem;
                            line-height: 3.68641rem;
                            /*font-family: 'Poppins';*/
                            font-style: normal;
                            font-weight: 700;
                            font-size: .8192rem;
                            color: rgba(4, 17, 29, 0.75);
                        }

                        .el-collapse-item__arrow.el-icon-arrow-right {
                            width: 1.2288rem;
                            height: 1.2288rem;
                            background: url(../assets/images/page-header/chevron_right.svg) no-repeat center center;

                            &:before {
                                display: none;
                            }
                        }

                        &__wrap {
                            border: 0;

                            .el-collapse-item__content {
                                .links {
                                    padding: 0px .8192rem;

                                    .item {
                                        height: 2.56001rem;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        text-decoration: none;

                                        &.p40 {
                                            justify-content: flex-start;
                                        }


                                        .community-li-text {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: .8192rem;
                                            line-height: 1.2288rem;
                                            /* identical to box height, or 150% */


                                            /* Black */

                                            // color: #000000;
                                            color: rgba(4, 17, 29, 0.75);

                                        }

                                        .community-li-icon {
                                            color: #000;
                                        }

                                        .iconImg {
                                            width: 1.2288rem;
                                            height: 1.2288rem;
                                            margin-right: 1.2288rem;

                                            a {
                                                img {
                                                    display: block;
                                                    height: 1.2288rem;
                                                }
                                            }
                                        }
                                    }
                                }

                                .list {
                                    .item {
                                        height: 2.45761rem;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 .8192rem;
                                        border-radius: .4096rem;

                                        .fl {
                                            display: flex;
                                            align-items: center;

                                            .img {
                                                width: 1.2288rem;
                                                height: 1.2288rem;
                                                margin-right: .4096rem;

                                            }

                                            .text {
                                                /*font-family: 'Poppins';*/
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: .8192rem;
                                                color: #707A83;
                                                font-weight: 600;

                                            }
                                        }

                                        .fr {}

                                        &:hover,
                                        &.ac {
                                            background: #F4F4F4;

                                            .fl {
                                                .text {
                                                    color: #000000;
                                                }
                                            }

                                        }

                                        &.ac {
                                            .fr {
                                                width: .4608rem;
                                                height: .4608rem;
                                                border-radius: .256rem;
                                                background: #FFC107;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

                .wallet {
                    position: absolute;
                    bottom: 1.024rem;
                    height: 2.56001rem;
                    left: 1.024rem;
                    right: 1.024rem;
                    background-color: #ffe161;
                    border-radius: .512rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /*font-family: 'Poppins';*/
                    font-style: normal;
                    font-weight: 700;
                    font-size: .8192rem;
                    line-height: 1.2288rem;
                    /* identical to box height */

                    text-align: center;

                    color: #614F00;

                }
            }
        }
    }
}
</style>
<style>
.el-tooltip__popper.is-dark {
    background: #FFFFFF !important;
    min-width: 219px !important;
    border: 1px solid #E6E6E6;
    box-shadow: 0 4px 0.25px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    top: 45px !important;
}

.el-tooltip__popper .popper__arrow {
    display: none;
}
</style>
<style lang="scss">
.more-chain-popover.el-popover {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;

    .more-info {
        .label {
            /*font-family: 'Poppins';*/
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */


            /* Black */

            color: #000000;
            margin-bottom: 4px;

        }

        .list {
            .item {
                margin-bottom: 3px;
                border-radius: 8px;
                padding: 14px 16px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .fl {
                    display: flex;
                    align-items: center;

                    .img {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                    }

                    .text {
                        /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: #707A83;
                        font-weight: 600;

                    }
                }

                .fr {}

                &:hover,
                &.ac {
                    background: #F4F4F4;

                    .fl {
                        .text {
                            color: #000000;
                        }
                    }

                }

                &.ac {
                    .fr {
                        width: 9px;
                        height: 9px;
                        border-radius: 5px;
                        background: #FFC107;
                    }
                }

            }
        }
    }
}

.wrong-network {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    height: 36px;
    line-height: 36px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;

    border: 0;
    padding: 0 16px;
    background: #FF495F !important;
    color: #fff !important;


    // font-family: Roboto-Regular, Roboto;
    font-family: Poppins-Bold, Poppins;

    &:hover {}
}

.wrong-network {
    width: 178px;

    img {
        margin-right: 5px;
        width: 24px;
        height: 24px;
    }

    &:hover {
        cursor: pointer;
    }
}

.header-drawer {
    height: calc(100% - 72px) !important;
    top: 72px;
    z-index: 99999999999 !important;

    .el-drawer {
        box-shadow: none !important;
        // animation: none !important;
    }

    .el-drawer__container.el-drawer__open {
        .el-drawer__header {
            display: none;
        }

        .el-drawer__body {
            .el-collapse {
                border: 0;

                &-item {
                    padding: 0 20px;

                    &__header {
                        border: 0;
                        height: 100%;
                        height: 72px;
                        line-height: 72px;
                        /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: rgba(4, 17, 29, 0.75);
                    }

                    .el-collapse-item__arrow.el-icon-arrow-right {
                        width: 24px;
                        height: 24px;
                        background: url(../assets/images/page-header/chevron_right.svg) no-repeat center center;

                        &:before {
                            display: none;
                        }
                    }

                    &__wrap {
                        border: 0;

                        .el-collapse-item__content {
                            .links {
                                padding: 0px 16px;

                                .item {
                                    height: 50px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    text-decoration: none;

                                    &.p40 {
                                        justify-content: flex-start;
                                    }


                                    .community-li-text {
                                        /*font-family: 'Poppins';*/
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 24px;
                                        /* identical to box height, or 150% */


                                        /* Black */

                                        // color: #000000;
                                        color: rgba(4, 17, 29, 0.75);

                                    }

                                    .community-li-icon {
                                        color: #000;
                                    }

                                    .iconImg {
                                        width: 24px;
                                        height: 24px;
                                        margin-right: 24px;

                                        a {
                                            img {
                                                display: block;
                                                height: 24px;
                                            }
                                        }
                                    }
                                }
                            }

                            .list {
                                .item {
                                    height: 48px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 0 16px;
                                    border-radius: 8px;

                                    .fl {
                                        display: flex;
                                        align-items: center;

                                        .img {
                                            width: 24px;
                                            height: 24px;
                                            margin-right: 8px;

                                        }

                                        .text {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 16px;
                                            color: #707A83;
                                            font-weight: 600;

                                        }
                                    }

                                    .fr {}

                                    &:hover,
                                    &.ac {
                                        background: #F4F4F4;

                                        .fl {
                                            .text {
                                                color: #000000;
                                            }
                                        }

                                    }

                                    &.ac {
                                        .fr {
                                            width: 9px;
                                            height: 9px;
                                            border-radius: 5px;
                                            background: #FFC107;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .wallet {
                position: absolute;
                bottom: 20px;
                height: 50px;
                left: 20px;
                right: 20px;
                background-color: #ffe161;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height */

                text-align: center;

                color: #614F00;

            }
        }
    }
}



.wallet-list-dialog {
    width: 400px;
    min-height: 201px;
    background: #FFFFFF;
    border: 1px solid #E5E8EB;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__header {
        height: 72px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #E5E8EB;

        .el-dialog__title {
            /*font-family: 'Poppins';*/
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;
            /* identical to box height, or 110% */
            text-align: center;
            color: #04111D;
        }

        .el-dialog__headerbtn {
            i {
                color: #8A939B;
                font-size: 24px;
            }
        }
    }

    .el-dialog__body {
        padding: 0;

        .wallet-list {
            padding: 8px 0;

            .wallet-item {
                display: flex;
                align-items: center;
                padding: 0 24px;
                height: 56px;
                cursor: pointer;

                img {
                    width: 24px;
                    margin-right: 16px;
                }

                .text {

                    /*font-family: 'Poppins';*/
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */


                    color: #04111D;

                }

                &:hover {
                    background: #FAFAFB;
                }
            }
        }
    }
}
</style>